import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import Landing from '../templates/Landing';

const host = process.env.GATSBY_HOST;

const people = {
  header: {
    title: 'peopleHeaderTitle',
    subTitle: 'peopleHeaderSubTitle',
    image: 'people.png',
    button1: {
      text: 'registration',
      subText: 'registrationSubText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`,
      event: 'PEOPLE_get_started',
      id: 'sign_up_people'
    },
    button2: {
      text: 'orderDemo',
      link: '/form/live-demo',
      event: 'PEOPLE_request_demo',
      id: 'request_demo_people'
    }
  },
  content: [
    {
      title: 'peopleContentLifeTitle',
      subTitle: 'peopleContentLifeSubTitle',
      image: 'people-automate.png',
    },
    {
      title: 'peopleContentDeshTitle',
      subTitle: 'peopleContentDeshSubTitle',
      image: 'people-control.png',
    },
    {
      title: 'peopleContentPulseTitle',
      subTitle: 'peopleContentPulseSubTitle',
      image: 'people-escalations.png',
    },
    {
      title: 'peopleContentControlTitle',
      subTitle: 'peopleContentControlSubTitle',
      image: 'people-manage.png',
    }
  ],
  demo: {
    event: 'digital_core_start'
  }
};


const People = (props) => (
  <Layout {...props}>
    <Landing
      data={people}
      page={'people'}
    />
  </Layout>
);

export default withIntl(People);

